import { useLocalStorage } from '@uidotdev/usehooks'
import { useRouter } from 'next/router'
import queryString from 'query-string'

import { useIsEntityPickerModal } from '@hooks/use_is_entity_picker_modal'

import { getValue, getQueryObject } from './helpers'

export function useQueryString() {
  const { isEntityPickerModal } = useIsEntityPickerModal()
  const [localStorage, setLocalStorage] = useLocalStorage(
    'entity-picker-filters',
    ''
  )
  const router = useRouter()
  const query = router.query

  const applyQueryString = (query) => {
    const queryStrings = queryString.stringify(query)
    const url = queryStrings
      ? `${router.pathname}?${queryStrings}`
      : router.pathname

    if (isEntityPickerModal) {
      setLocalStorage(queryStrings)
    } else {
      router.push(url)
    }
  }

  const setQueryString = (name, value = undefined) => {
    query[name] = getValue(value)

    applyQueryString(query)
  }

  const removeAllQueryStrings = () => {
    setLocalStorage('')
  }

  const removeQueryString = (name) => setQueryString(name)

  const setManyQueryStrings = (filters) => {
    Object.keys(filters).forEach((key) => {
      query[key] = getValue(filters[key])
    })

    applyQueryString(query)
  }

  return {
    query: getQueryObject(isEntityPickerModal, query, localStorage),
    setQueryString,
    setManyQueryStrings,
    removeQueryString,
    removeAllQueryStrings
  }
}
