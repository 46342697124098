import cookies from 'js-cookie'

import { prependModernHost, getUrl } from '@helpers/routes'

import { POP_UP_HEIGHT, POP_UP_WIDTH, TOKEN_COOKIE } from './constants'

export const getIdUrl = () =>
  prependModernHost(getUrl('login')).replace(/^https?:\/\//, '')

export const handleLogin = (popUpUrl, loginUrl, loginSuccess, loginError) => {
  const popUp = createPopup(popUpUrl)
  /* istanbul ignore next */
  waitForPopupLogin(popUp, loginUrl).then(loginSuccess).catch(loginError)
}

export const createPopup = (popUpUrl) => {
  const left = screen.width / 2 - POP_UP_WIDTH / 2
  const top = screen.height / 2 - POP_UP_HEIGHT / 2
  const options = `width=${POP_UP_WIDTH},height=${POP_UP_HEIGHT},left=${left},top=${top}`

  return window.open(popUpUrl, '_blank', options)
}

export const waitForPopupLogin = (window, loginUrl) => {
  /* istanbul ignore next */
  return new Promise((resolve, reject) => {
    const polling = setInterval(() => {
      try {
        const loginStatusParams = pollLoginStatus(window, loginUrl)

        if (loginStatusParams !== null) {
          resolve(loginStatusParams)
          clearInterval(polling)
        }
      } catch (e) {
        // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
        // A hack to get around same-origin security policy errors in browsers.
        if (e.constructor.name === 'DOMException') return

        reject(e)
        clearInterval(polling)
      }
    }, 500)
  })
}

export const pollLoginStatus = (win, loginUrl) => {
  if (isClosed(win)) throw new Error('The popup window was closed')

  if (!hasPopupChangedPath(win, loginUrl)) return null

  return getParamsFromPopup(win)
}

export const isClosed = (win) => {
  return !win || win.closed || win.closed === undefined
}

export const getParamsFromPopup = (win) => {
  if (!hasParams(win)) {
    throw new Error(
      'OAuth redirect has occurred but no query or hash parameters were found.'
    )
  }

  const params = getRedirectUrlParams(win)

  win.close()

  if (params.error) {
    throw new Error(params.error)
  }

  return params
}

export const hasPopupChangedPath = (win, loginUrl) => {
  const popupUrlPath = win.location.host + win.location.pathname
  return popupUrlPath === loginUrl
}

export const hasParams = (win) => {
  return win?.location?.search || win?.location?.hash
}

export const getRedirectUrlParams = (win) => {
  const query = win.location.search
  const trimmedQuery = new URLSearchParams(query)
  const queryObject = Object.fromEntries(trimmedQuery)

  const hash = win.location.hash.replace(/^#/, '')
  const trimmedHash = new URLSearchParams(hash)
  const hashObject = Object.fromEntries(trimmedHash)

  const params = Object.assign({}, queryObject, hashObject)

  return params
}

export const handleOAuthLogin = (data) => {
  cookies.set(TOKEN_COOKIE, data.access_token, {
    domain: process.env.NEXT_PUBLIC_SSO_TOKEN_DOMAIN,
    expires: 7
  })
}

export const getUrlParams = () => {
  const params = new URLSearchParams({
    client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
    redirect_uri: prependModernHost(getUrl('login')),
    display: 'popup',
    response_type: 'token'
  })

  return `${
    process.env.NEXT_PUBLIC_ID_URL
  }/oauth/authorize?${params.toString()}`
}
