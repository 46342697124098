import queryString from 'query-string'

export const getValue = (value) => {
  if (value === null || value === '') return undefined

  return value?.value || value
}

export const getQueryObject = (isEntityPickerModal, query, localStorage) => {
  if (isEntityPickerModal) return queryString.parse(localStorage)

  return query
}
