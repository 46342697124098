import React from 'react'

import { useAlerts } from '@hooks/use_alerts'
import { useQueryString } from '@hooks/use_query_string'

import { LOGIN_BUTTON_TEXT, PION_LOGO_ALT, PION_LOGO_PATH } from './constants'
import {
  handleLogin,
  getIdUrl,
  handleOAuthLogin,
  getUrlParams
} from './helpers'
import * as styles from './styles'

const Login = () => {
  const { displayErrorAlert } = useAlerts()
  const { query } = useQueryString()

  /* istanbul ignore next */
  const loginSuccess = (params) => {
    /* istanbul ignore next */
    handleOAuthLogin(params)
    /* istanbul ignore next */
    window.location.assign(query.redirect || '/')
  }

  /* istanbul ignore next */
  const loginError = (error) => {
    /* istanbul ignore next */
    return displayErrorAlert(`${error}`)
  }

  return (
    <div css={styles.container}>
      <div css={styles.pionLogo}>
        <img
          alt={PION_LOGO_ALT}
          width={'200'}
          src={PION_LOGO_PATH}
        />
      </div>
      <div css={styles.buttonContainer}>
        <button
          onClick={() =>
            handleLogin(getUrlParams(), getIdUrl(), loginSuccess, loginError)
          }
          css={styles.signInButton}
        >
          {LOGIN_BUTTON_TEXT}
        </button>
      </div>
    </div>
  )
}

export default Login
