import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const signInButton = css`
  background-color: ${Colours('berryBlue', 400)};
  color: white;
  width: 300px;
  height: 50.38px;
  border: none;
  padding: 0px 26px;
  margin: 50px;
`

export const pionLogo = css`
  margin-bottom: 30px;
`

export const container = css`
  background-color: ${Colours('huckleberry', 500)};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const buttonContainer = css`
  background-color: ${Colours('white', 300)};
  border-radius: 5px;
`
